import * as Sentry from '@sentry/browser';
import { getQuestionnaireRoute } from 'functions/getQuestionnaireRoute';
import { QUESTION_IDS } from 'new-ui/Components/Intake';
import { authHeaders } from '../../functions/authHeader';
import configs from '../../configs';
import handleResponse from '../../functions/handleResponse';

const apiUrl = configs.beApiUrl;

const getQuestionRoutePath = (questionId, condition, additionalParams) => {
  if (questionId === QUESTION_IDS.BIOMARKERS) {
    return `question/${questionId}${condition ? `/${condition}` : ''}`;
  }

  if (questionId === 'drugs_received') {
    let path = `question/${questionId}`;
    if (condition) {
      path += `/${condition}`;
      if (additionalParams) {
        path += `?${new URLSearchParams(additionalParams).toString()}`;
      }
    }
    return path;
  }

  return `question/${questionId}`;
};

const getQuestionOptions = async (questionId, condition, additionalParams) => {
  const requestOptions = {
    method: 'GET',
    headers: await authHeaders(),
  };

  return fetch(apiUrl + getQuestionnaireRoute(
    { questionId: getQuestionRoutePath(questionId, condition, additionalParams) },
  ), requestOptions)
    .then(handleResponse)
    .catch((ex) => {
      Sentry.captureException(ex);
      console.error('Error occurred, while getting profile data:', ex);
    });
};

const getDiseasesList = async () => {
  const response = await fetch(apiUrl + getQuestionnaireRoute({ questionId: 'diseases' }));
  const { data } = await response.json();

  return data;
};

const getQuestionnaireStructure = async (diseaseId, additionalParams) => {
  const route = getQuestionnaireRoute({ questionId: diseaseId, additionalParams });
  const response = await fetch(apiUrl + route);
  const { data } = await response.json();

  return data;
};

export const questionnaireService = {
  getQuestionOptions,
  getDiseasesList,
  getQuestionnaireStructure,
};
