import moment from 'moment';
import api, { getBiomarkers, getTrials, getIndications } from 'new-ui/api';
import { indicationChooser } from 'components/Questionnaire/functions';

import { store } from 'utils/store';
// import mock, { groupedSOC } from 'new-ui/mock';

import { authenticationActions } from 'components/Authentication/actions';
import fn, { DOM, getLoggedUser } from 'new-ui/functions';
import clearAuthDataInLocalStorage from 'functions/clearAuthDataInLocalStorage';
import { loadOptions } from 'new-ui/functions/loadOptions';
import { treatmentsMock, trialsMock, treatmentsCategoriesMock } from 'new-ui/mock';
import sendGoogleAnalyticsEvent from './Components/GoogleAnalytics';
import { preventIframeInteraction } from './Components/OpenInNewTab';
import {
  MATCH_FLOWS, AUDIT_COMMENTS, POPUP_TYPES, INTAKE_INDICATIONS, ROUTES, PAGES, ENV_TYPES,
  SUBTYPE_FIELD_BY_ALL_CONDITIONS,
} from './constants';
import Intercom from './Components/Intercom';
import Iframe from './Components/Iframe';
import ChatbotIntakeFlow from './Components/ChatBot/Flows/ChatbotIntakeFlow';

import Provider from './Components/Provider/Provider';
import { getDrugs } from './Components/QuestionRenderer/renderers/MultiPages/api';

class App {
  constructor() {
    this.fn = fn;
    this.inited = false; // happens before everything
    this.loaded = false; // after login and results
    this.store = store;
    this.cache = {};
    this.intercom = Intercom;
    this.intercom.app = this;
    this.version = '1';
    this.treatmentsMock = treatmentsMock;
    this.trialsMock = trialsMock;
    this.treatmentsCategoriesMock = treatmentsCategoriesMock;

    this.sendGoogleAnalyticsEvent = (category, eventName, metaData)=>{
      console.log('sendGoogleAnalyticsEvent :: ', {
        category,
        eventName,
        metaData,
      });
      sendGoogleAnalyticsEvent(category, eventName, metaData);
    };

    window.__chatbotIntake = ChatbotIntakeFlow;
  }

  async init(setInit) {
    this.inited = true;

    this.trials = [];

    if (Array.isArray(this.treatments) && this.treatments.length) {
      console.log('DEV-6831 issue reproduce. Treatments length', this.treatments.length);
    }
    /*
      For a some reason line below override treatments from the backend responce
      so we get matches result earlier than app.init() called
     */
    this.treatments = this.treatments || [];
    this.documents = null;

    const params = new URLSearchParams(window.location.search);

    this.getBiomarkers();

    if (params.get('iframe')) {
      const frame = new Iframe({
        url: decodeURIComponent(params.get('iframe')),
      });
      frame.show();
    }

    if (params.get('provider')) {
      const provider = params.get('provider');
      await this.getConfiguration(provider);
    }

    this.getIndications = getIndications;
    const drugs = await getDrugs();
    this.drugs = drugs.data;

    console.log('setInit true');

    setInit(true);
  }

  async getQuestions(indication = this.user?.personal.condition, preventCaching, conditionProfile = this.user?.condition_profile) {
    if (!preventCaching && this.questions) return this.questions;

    const diseaseSubTypeField = SUBTYPE_FIELD_BY_ALL_CONDITIONS[indication];
    const diseaseSubTypeValue = conditionProfile?.[SUBTYPE_FIELD_BY_ALL_CONDITIONS[indication]];
    const additionalParams = diseaseSubTypeValue ? { diseaseSubTypeField, diseaseSubTypeValue } : {};

    const _questions = await indicationChooser(indication, additionalParams);
    const questions = JSON.parse(JSON.stringify(_questions));
    for (let i = 0; i < questions.length; i++) {
      const q = questions[i];
      // eslint-disable-next-line
        await loadOptions(q, indication, additionalParams);
      if (q.subQuestions) {
        for (let ii = 0; ii < q.subQuestions.length; ii++) {
          const sq = q.subQuestions[ii];
          // eslint-disable-next-line
            await loadOptions(sq, indication, additionalParams);
        }
      }
    }
    return questions;
  }

  async getBiomarkers() {
    getBiomarkers().then((biomarkers) => {
      this.biomarkers = [{
        code: 'none',
        name: 'general.no_known_biomarkers',
      }, ...biomarkers];
    });
  }

  clearCache() {
    this.cache = {};
  }

  logout(silentLogout = false) {
    clearAuthDataInLocalStorage();
    localStorage.clear();
    if (silentLogout) {
      if (typeof silentLogout === 'string') return window.location = silentLogout;
      return;
    }
    let loginLink = '/login';
    if (this.provider?.code) loginLink += (`?provider=${this.provider.code}`);
    window.location = loginLink;
    delete this.user;
  }

  getLang() {
    return this.store.getState().main.lang;
  }

  async getUser() {
    const params = new URLSearchParams(window.location.search);
    if (params.get('detach')) await Provider.detach(AUDIT_COMMENTS.SEE_ALL_OPTIONS, true);
    const user = await this.store.dispatch(await authenticationActions.getUser());
    // user.personal.providerCode = 'pfizer' // mock;
    await this.getConfiguration(user?.personal?.providerCode);
    this.questions = await this.getQuestions(user.personal.condition, true, user.condition_profile);
    if (document.referrer === 'https://www.pfizerclinicaltrials.com/' && !user?.personal?.providerCode) preventIframeInteraction();
    return user;
  }

  async setUser() {
    this.user = await this.getUser();
  }

  async getSession() {
    if (getLoggedUser()) {
      this.user = await this.getUser();
      if (localStorage.getItem('set_patient') && this?.user?.patients?.length) {
        const patientWixId = localStorage.getItem('set_patient');
        const patient = this.user.patients.find((p)=>p.personal.user_wix_id === patientWixId);
        if (patient) {
          app.hcpUser = patient;
        }
      }
    }
    return {
      user: this.user,
    };
  }

  setMock() {
    this.trials = this.trialsMock;
    this.treatments = this.treatmentsMock;
    this.treatmentsCategories = this.treatmentsCategoriesMock;
  }

  isMock() {
    return this.getParams().get('mock');
  }

  isProduction() {
    return window.ENV === ENV_TYPES.PRODUCTION;
  }

  isSOCSupported() {
    if (window.location.pathname.startsWith(ROUTES[PAGES.SEARCH])) return true;
    if (this.getParams().get('soc')) return true;
    const diseasesSupportSOC = this.coniguration?.drugIntegrations || [];
    const condition = this.user?.personal?.condition;
    return this.isMock() ? true : diseasesSupportSOC.includes(condition);
  }

  async getTrials(matchFlow = MATCH_FLOWS.LATEST, onload) {
    const params = new URLSearchParams(window.location.search);
    if (!this.user || this.fetchingMatch) return;
    if (this.isMock()) return this.setMock();
    document.body.classList.add('fetching-data');
    const { lang } = this.store.getState().main;
    let result = null;
    try {
      this.fetchingMatch = true;
      result = await getTrials(this.user, matchFlow, lang, params.get('newest'));
      this.fetchedTrials = true;
      this.fetchingMatch = false;
      console.log('app getTrials result', result);
    } catch (error) {
      this.crash();
      throw error;
    }
    // const result = groupedSOC;

    if (result.providersPopup) Provider.setPopup(result.providersPopup);

    this.matchesResult = result;

    this.trials = this.matchesResult.result;
    this.treatments = this.matchesResult.treatments; // treatmentsMock3; //
    this.treatmentsCategories = this.matchesResult?.treatmentCategoryTree?.map((treatment) => {
      const treatments = treatment.nct_numbers
        .map((id) => this.matchesResult.treatments
          .find(({ nct_number }) => nct_number === id)).filter((i) => i);
      return {
        ...treatment,
        treatments,
      };
    });

    if (params.get('qa') && this.trials?.length) {
      this.trials.forEach((t, i) => {
        if (Number(params.get('qa')) <= i) return;
        this.trials[i].isNew = true;
      });
    }

    if (onload) onload();

    document.body.classList.remove('fetching-data');
    return result;
  }

  async onFirstLoad() {
    this.loaded = true;
    console.log('onFirstLoad');
    const params = new URLSearchParams(window.location.search);

    if (params.get('q') && params.get('answer')) {
      const questionKey = params.get('q');
      await app.answerQuestion({ [questionKey]: params.get('answer') });
      if (params.get('toast')) app.alert(params.get('toast'));
      if (params.get('redirect_popup')) app.setPopup(params.get('redirect_popup'));
    }

    if (
      moment().diff(moment(this.user?.personal?.profile_updated_at), 'days') >= 30
    ) {
      if (!this.popup && !this.getParams().get('popup') && !this.isCurrentPage(PAGES.INTAKE) && !this.isHcpPage()) this.setPopup(POPUP_TYPES.HAS_ANYTHING_CHANGED);
    }
  }

  async update(settings = {}) {
    if (this.isUpdating) return;
    if (this.onupdatestart) this.onupdatestart();

    const params = new URLSearchParams(window.location.search);

    let user = null;
    let { rematch } = settings;

    if (params.get('update')) {
      params.delete('update');
    }

    if (params.get('rematch')) {
      params.delete('rematch');
      rematch = true;
    }

    if (settings.rematch && this.user) {
      user = await this.getUser();

      const oldUser = { ...this.user };
      const newUser = { ...user };

      const MONTH_MILIS = 2.628e+9;

      const hasUpdated = oldUser.personal.profile_updated_at !== newUser.personal.profile_updated_at;
      const createdAt = new Date(oldUser.personal.profile_created_at);
      const now = new Date();

      if (hasUpdated || createdAt.getTime() + MONTH_MILIS >= now.getTime()) { // profile updated or within 30 days of registration - do rematch
        this.clearCache();
      } else {
        rematch = false;
      }
    }

    this.isUpdating = true;
    this.user = user || this.user || await this.getUser();
    this.isUpdating = false;

    if(!app.isCurrentPage(PAGES.INTAKE)){
      await this.getTrials(rematch ? MATCH_FLOWS.BASIC : MATCH_FLOWS.LATEST);
    }

    console.log('1', this.trials);

    if (this?.user?.missing_critical_info) { // until supported
      delete this.user.missing_critical_info.currentStage;
      delete this.user.missing_critical_info.mProteinDetection;
      delete this.user.missing_critical_info.cllCharasterictics;
      delete this.user.missing_critical_info.cancerSubType;
      delete this.user.missing_critical_info.percentageOfBmBlasts;
      delete this.user.missing_critical_info.transplantationStatus;
    }

    if (!this.intercom.user) {
      this.intercom.boot();
    } else {
      this.intercom.update();
    }

    params.delete('token');

    window.history.pushState({}, document.title, `${window.location.pathname}${params.toString() ? `?${params.toString()}` : ''}`);

    if (!this.loaded) await this.onFirstLoad();

    if (this.onupdateend) this.onupdateend();
  }

  isCurrentPage(page) {
    if (window.location.pathname === ROUTES[page]) return true;
  }

  isHcpPage() {
    return this.isCurrentPage(PAGES.SEARCH) || this.isCurrentPage(PAGES.PATIENTS) || this.isCurrentPage(PAGES.FINANCE_INTAKE) || this.isCurrentPage(PAGES.PUBLIC_FINANCE);
  }

  getParams() {
    return new URLSearchParams(window.location.search);
  }

  numResults(onlyTrials) {
    if (onlyTrials) return (this.trials?.length || 0);
    return ((this.trials?.length || 0) + (this.treatments?.length || 0));
  }

  async getConfiguration(provider) { // provider is code
    this.coniguration = await api.getConfiguration({ provider });
    if (this.coniguration.providerSpecific?.code) {
      this.provider = this.coniguration.providerSpecific;
      Provider.setTheme(this.coniguration.providerSpecific);
    }
  }

  async setConfiguration(data = {}) {
    if (!this.coniguration) throw new Error('setConfiguration: No configuration object');
    this.coniguration.data = Object.assign(this.coniguration.data || {}, data);
    return api.setConfiguration(this.coniguration.data);
  }

  async resetConfiguration() {
    this.coniguration.data = {};
    return api.setConfiguration(this.coniguration.data);
  }

  gotoDashboard() {
    this.history.push(ROUTES[PAGES.RESULTS]);
  }

  hasCriticalMissingData() {
    return this.fn.objectSome(this?.user?.missing_critical_info, (value) => value === true);
  }

  hasTrialsMissingCritical() {
    return this.trials.some((t) => t.missed_questions.length);
  }

  async updateUser(user = this.user, preventRematch, oldUser) {
    await this.store.dispatch(authenticationActions.updateUser(user, true, oldUser || app.user));
    if (!preventRematch) await this.update({ rematch: true });
  }

  showPreloader() {
    if (this.setShowPreloader) this.setShowPreloader(true);
  }

  hidePreloader() {
    if (this.setShowPreloader) this.setShowPreloader(false);
  }

  crash() {
    if (this.setAppCrashed) this.setAppCrashed(true);
    this.hidePreloader();
  }

  setDevMode() {
    localStorage.setItem('isDev', 1);
  }

  setIntakeFlow(set) {
    if (set) localStorage.setItem('intake-mode', 1);
    else localStorage.removeItem('intake-mode');
  }

  isIntakeFlow(condition = this.user?.personal.condition) {
    if (localStorage.getItem('intake-mode')) return true;
    if (!condition) return false;
    return INTAKE_INDICATIONS.includes(condition);
  }

  addDocuments(data) {
    this.documents = data;
  }

  getDocuments() {
    return this.documents;
  }

  setQuickNote(data = { title: 'hello world!' }) {
    const $note = DOM('.quick-note-holder.note-hidden', {
      '/.quick-note': {
        innerHTML: data.title,
      },
    });
    document.body.appendChild($note);
    setTimeout(()=>{
      $note.classList.remove('note-hidden');
      setTimeout(()=>{
        $note.classList.add('note-hidden');
        setTimeout(()=>{
          document.body.removeChild($note);
        }, 1500);
      }, 3000);
    }, 10);
  }
}

const app = new App();

window.app = app;

export default app;
